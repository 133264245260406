.orgchart {
  height: 100%;
  width: 100%;
  background: none;
  display: flex;
  justify-content: center;
}
.orgchart ul li .oc-node {
  padding: 0;
  border: 2px solid transparent;
  margin-bottom: 20px;
}
/* nodes */
.orgchart ul li .oc-node .oc-heading {
  width: auto;
  min-width: 150px;
  height: 30px;
  line-height: 30px;
  background-color: rgba(33, 150, 243, 0.8);
}
.orgchart ul li .oc-node .oc-heading-dept {
  width: auto;
  min-width: 100px;
  height: 25px;
  line-height: 25px;
  background-color: rgba(5, 194, 15, 0.8);
}
.orgchart ul li .oc-node .oc-heading-rolegroup {
  min-width: 100px;
  background-color: rgba(235, 207, 52, 0.8);
}
.oc-menu-btn {
  position: relative;
  cursor: pointer;
  padding: 5px;
}
.oc-menu {
  position: absolute;
  border: 1px solid red;
  cursor: pointer;
  padding: 5px;
  background-color: aliceblue;
  color: black;
}
.orgchart ul li .oc-node .oc-content {
  width: auto;
  min-width: 150px;
  height: 35px;
  line-height: 30px;
  border: 1px solid rgba(33, 150, 243, 0.8);
}
.orgchart ul li .oc-node .oc-content-dept {
  width: auto;
  min-width: 100px;
  height: 27px;
  line-height: 22px;
}
.orgchart ul li .oc-node .oc-content-rolegroup {
  min-width: 100px;
}
/* lines */
.orgchart > ul > li > ul li::before {
  border-top: 2px solid rgba(33, 150, 243, 0.8);
}
.orgchart > ul > li > ul li > .oc-node::before {
  background-color: rgba(33, 150, 243, 0.8);
}
.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: rgba(33, 150, 243, 0.8);
}
.orgchart .menu-icon {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7;
  z-index: 1;
}
.orgchart .menu-icon-dept {
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7;
  z-index: 1;
}
