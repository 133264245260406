.preview2 {
  flex: 1 0 auto;
  display: flex;
  outline: 0;
  z-index: 1000;
  position: fixed;
  width: 200px;
  height: 100px;
  /* border-left: 1px solid #212529; */
  background-color: white;
  border: 1px solid #212529;
}
.preview {
  flex: 1 0 auto;
  display: flex;
  outline: 0;
  z-index: 1500;
  position: fixed;
  width: 200px;
  height: 100px;
  /* border-left: 1px solid #212529; */
  background-color: white;
  border: 1px solid #212529;
}
.konvaArea {
  background-color: white;
  overflow: auto;
  position: absolute;
  border: 1px solid #212529;
  background-image: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 0);
}
