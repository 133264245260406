.dialog{
    flex: 1 0 auto;
    display: flex;
    outline: 0;
    z-index: 1100;
    position: fixed;
    /* float:right; */
    overflow-y: auto;
    flex-direction: column;
    width:200px;
    /* border-left: 1px solid #212529; */
    background-color: white;
    border: 1px solid #212529;
}
.control-btn{
    /* width:100%; */
    margin: 2px 5px 2px 5px;
    background-color: white;
    
}
.closeicon{
    float: right;
    height: 24px;
    padding: 2px;

}
.closeicon:hover {
    cursor:pointer;
}

.fillarea{
    width: 100%;
    padding: 0px 12px;
    z-index: 1100;
}

.title{
    margin: 2px 0px;
}

.inputarea{
    width: 100%;
    margin: 5px 0px;
}

.showArea{
    width: 100%;
    margin: 5px 0px;
    background-color:#4791db;
    color:white; 
}

.confirm-btn{
    margin: 0 auto;
    display: flex;
}

.confirm-btn:disabled {
    cursor:not-allowed;
}

.list{
    display: flex;
    width: 100%;
    margin: 5px 0px;
}