.menu {
    position: absolute;
    width: 100px;
    background-color: white;
    box-shadow: 0 0 5px grey;
    border-radius: 3px;
    display:initial;
    z-index: 1100;
  }

.normal-button {
    width: 100%;
    background-color: #4791db;
    border: none;
    margin: 0;
    padding: 10px;
    color:white; 
  }

  .control-button {
    width: 100%;
    background-color: #81c784;
    border: none;
    margin: 0;
    padding: 10px;
    color:white; 
  }

.menu button:hover {
    background-color: lightgray;
    color: black;
}

.close-button {
  width: 100%;
  background-color: #e57373;
  border: none;
  margin: 0;
  padding: 10px;
  color:white;
}