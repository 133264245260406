.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1100;
  position: fixed;
  padding: 0 10px;
  height: 46px;
  border: solid 1px #000000;
  background-color: white;
}
.controls {
  display: flex;
  align-items: center;
}
.control {
  display: contents;
}
.controlSelect {
  width: 200px;
  display: contents;
}
.divider {
  border-left: solid 2px #000000;
  height: 40px;
  /* vertical-align: middle; */
  display: inline-block;
  margin: 0px 5px 0px 5px;
}
.slideText {
  height: 40px;
  vertical-align: middle;
  display: contents;
  margin: 0px 10px;
}
.slideBar {
  /* vertical-align: middle; */
  display: inline-block;
  margin: 0px 5px 10px 5px;
  width: 120px;
}
.adjectControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.control-btn-1 {
  /* width:100%; */
  margin: 2px 5px 2px 5px;
  background-color: #81c784;
}
.control-btn-1:hover {
  background-color: lightgray;
}
.control-btn-2 {
  /* width:100%; */
  margin: 2px 5px 2px 5px;
  background-color: #ffb74d;
}
.control-btn-2:hover {
  background-color: lightgray;
}
.control-btn-3 {
  /* width:100%; */
  margin: 2px 5px 2px 5px;
  background-color: #64b5f6;
}
.control-btn-3:hover {
  background-color: lightgray;
}
.top-control-btn {
  margin: 2px 5px 2px 5px;
  /* color: #FFFFFF; */
  /* background-color: #3f51b5; */
  height: 40px;
  width: 40px;
  border: none;
  /* box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
        border-radius: 4px; */
}
.top-control-btn:hover {
  fill: #ffffff;
  background-color: #57c5f7;
  cursor: pointer;
}
.customeSwitch {
  margin: 0px 10px;
}
.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 64px;
  height: 22px;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #57c5f7;
  background-color: #57c5f7;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
  top: 0;
}
.rc-switch:after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 2px;
  top: 1px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-duration: 0.3s;
  animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
  transform: scale(1.1);
  animation-name: rcSwitchOn;
}
.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}
.rc-switch-checked {
  border: 1px solid #57c5f7;
  background-color: #57c5f7;
}
.rc-switch-checked .rc-switch-inner {
  left: 6px;
}
.rc-switch-checked:after {
  left: 42px;
}
.rc-switch-disabled {
  cursor: no-drop;
  background: #57c5f7;
  border-color: #57c5f7;
}
.rc-switch-disabled:after {
  background: #9e9e9e;
  animation-name: none;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  transform: scale(1);
  animation-name: none;
}
.rc-switch-label {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  user-select: text;
}
@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
