.ant-table,
.ant-table.ant-table-small {
  font-size: 12px;
}
.ant-table-wrapper ::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
.ant-table-wrapper ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.3);
}
.ant-table-wrapper ::-webkit-scrollbar-track {
  box-shadow: 0;
  -webkit-box-shadow: 0;
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}
