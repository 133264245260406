.ruleDetail {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
}
.ruleDetail .inputArea {
    width: 200px;
    display: flex;
}
.ruleDetail .inputArea .content-button {
    margin: 10px 0px 10px 20px;
}