.leftbar{
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1100;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    width:200px;
    border: solid 1px #000000;
    /* background-color: #2196f3; */
}
.leftbar ul {display:flex; background-color: white; border-bottom: 1px solid #eee; flex-direction: row; margin: 0;list-style-type:none;}
.leftbar ul li{text-align: center; color: #546E7A;padding:0px 5px;}
.leftbar ul li.active{border-bottom: 2px solid rebeccapurple;color: rebeccapurple}
.leftbar ul li:hover{cursor:pointer; color: #64b5f6}
